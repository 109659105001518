<template>
  <v-container fluid class="store-detail-page pa-0">
    <v-row no-gutters justify="center">
      <v-col cols="12" class="image-store">
        <v-img cover :src="couponById.image_url" max-height="400px">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="#463729"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <img src="/Image/close.png" alt @click="$router.push('/')" />
      </v-col>
      <v-col cols="12" class="content px-3">
        <v-row justify="center">
          <v-col cols="12" class="header-store px-3 pb-0 pt-3">{{$i18n.locale == 'th' ? couponById.name: couponById.name_en}}</v-col>
          <v-col cols="12" class="title-store px-3 py-0">{{$i18n.locale == 'th' ? couponById.title : couponById.title_en}}</v-col>
          <v-col cols="12" class="subtitle-store">{{$i18n.locale == 'th' ? couponById.detail: couponById.detail_en}}</v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-btn class="bottom-button" block height="50px" @click="$router.push('/PaymentDetail/' + $route.params.id)">
      {{$t('PURCHASE')}}</v-btn>
  </v-container>
</template>

<script>
  export default {
    name: "StoreDetail",
    computed: {
      couponById() {
        return this.$store.getters.couponById(this.$route.params.id);
      },
    },
  };
</script>

<style lang="scss">
  @import "@/assets/scss/color.scss";

  .store-detail-page {
    background: url("/Image/BG_4.png");
    background-size: contain;
    background-repeat: repeat;
    padding: 0px;
    height: 100%;

    .image-store {
      position: relative;

      img {
        position: absolute;
        top: 1.5vh;
        right: 1.5vh;
      }
    }

    .content {
      padding: 0px 16px 60px 16px;
      min-height: 100%;

      .header-store {
        font-weight: 800;
        font-size: 20px;
      }

      .date-store {
        color: #969696;
        font-size: 12px;
      }

      .title-store {
        font-weight: 800;
        font-size: 14px;
      }

      .subtitle-store {
        margin-top: 25px;
        font-weight: 400;
        color: #969696;
        font-size: 14px;
      }
    }

    .bottom-button {
      position: fixed;
      bottom: 0;
      left: 0;
      background-color: $primary-color !important;
      border-radius: 0px !important;
    }
  }
</style>